import { ref, computed, watch } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { isEnableBed, parseDateToString } from '@/auth/utils';
import incomeExpenseStoreModule from '../incomeExpenseStoreModule';

export default function useIncomeExpenseModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'incomeExpense';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpenseStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const allocations = ref([]);

  const resetModal = () => {
    resetItemLocal();
  };

  // const items = computed(() => itemLocal.value.items);
  const tenant = computed(() => itemLocal.value.tenant);

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      isIncome: itemLocal.value.isIncome,
      name: itemLocal.value.name,
      note: itemLocal.value.note,
      receiver: itemLocal.value.receiver,
      payer: itemLocal.value.payer,
      repeatCycle: itemLocal.value.repeatCycleObject ? itemLocal.value.repeatCycleObject.value : '0',
      repeatInfinity: itemLocal.value.repeatInfinity,
      repeatCount: itemLocal.value.repeatCount,
      allocation: itemLocal.value.allocation,
      items: itemLocal.value.items.map(object => {
        const tempItem = {
          startDate: parseDateToString(object.startDate),
          endDate: parseDateToString(object.endDate),
          amount: object.amount,
          incomeExpenseTypeId: object.incomeExpenseType.id,
        };
        if (object.id && object.id > 0) {
          tempItem.id = object.id;
        }
        return tempItem;
      }),
    };

    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      data.apartmentId = itemLocal.value.apartment.id;
    }
    if (itemLocal.value.room && itemLocal.value.room.id > 0) {
      data.roomId = itemLocal.value.room.id;
    }
    if (itemLocal.value.bed && itemLocal.value.bed.id > 0) {
      data.bedId = itemLocal.value.bed.id;
    }
    if (itemLocal.value.tenant && itemLocal.value.tenant.id > 0) {
      data.tenantId = itemLocal.value.tenant.id;
    }
    if (itemLocal.value.contract && itemLocal.value.contract.id > 0) {
      data.contractId = itemLocal.value.contract.id;
    }
    if (itemLocal.value.cashbook && itemLocal.value.cashbook.id > 0) {
      data.cashbookId = itemLocal.value.cashbook.id;
    }

    // attachments
    if (itemLocal.value.attachments) {
      data.attachments = itemLocal.value.attachments.map(object => object.location);
    }

    data.issueDate = parseDateToString(itemLocal.value.issueDate);

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('incomeExpense/updateIncomeExpense', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('incomeExpense/createIncomeExpense', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  // const calculateAllocation = val => {
  //   if (val && val.length > 0) {
  //     const data = {
  //       items: val.map(obj => ({
  //         id: obj.incomeExpenseType.id,
  //         name: obj.incomeExpenseType.name,
  //         amount: obj.amount,
  //         startDate: obj.startDate,
  //         endDate: obj.endDate,
  //       })),
  //     };
  //     store.dispatch('incomeExpense/calculateAllocation', data)
  //       .then(response => {
  //         allocations.value = response.data;
  //       })
  //       .catch(error => {
  //         toastification.showToastError(error, refForm.value);
  //       });
  //   } else {
  //
  //   }
  // };

  const changeType = val => {
    itemLocal.value.isIncome = val;
  };

  const onSelectIncomeExpenseType = val => {
    const arrMixUpItems = [...itemLocal.value.items, ...val.map(obj => ({
      incomeExpenseType: obj,
      amount: 0,
      startDate: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
    }))];
    itemLocal.value.items = arrMixUpItems;
  };

  // watch(items, val => {
  //   calculateAllocation(val);
  // }, { deep: true });

  watch(tenant, val => {
    if (val && itemLocal.value.id === 0) {
      itemLocal.value.payer = val.name;
      itemLocal.value.receiver = val.name;
    }
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 6;
    }
    if (isBed) {
      return 0;
    }
    return 12;
  };

  const onDeleteAttachment = () => { };

  return {
    refModal,
    refForm,
    itemLocal,
    allocations,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onSelectIncomeExpenseType,
    resolveColWidthIfDisableBed,
    onDeleteAttachment,
    changeType,
  };
}
