<template>
  <b-modal
    id="modal-select-income-expense-type"
    ref="my-modal"
    size="lg"
    title="Chọn hạng mục"
    ok-title="Chọn"
    cancel-title="Hủy"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="checkIsSubmit"
    @hidden="resetValue"
    @show="fetchData"
    @ok="handleSubmit"
  >
    <b-button
      v-b-modal.modal-income-expense-type
      size="md"
      variant="primary"
    >
      Thêm
    </b-button>
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
          }"
          @on-selected-rows-change="selectionChanged"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="loadingContent">
            <b-spinner
              label="Loading"
              type="grow"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Code -->
            <span v-if="props.column.field === 'code'">
              <b-link class="font-weight-bold">
                {{ props.row.code }}
              </b-link>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <table-actions
                name="Loại thu/Chi"
                modal="modal-income-expense-type"
                :hide-print="false"
                :hide-delete="!$can('delete', 'income-expense')"
                :hide-edit="true"
                @on-delete="onDelete(props.row)"
                @on-edit="onEditItem(props.row)"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị tối đa </span>
                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '20', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  trên tổng số {{ props.total }} kết quả
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div slot="emptystate">
            <div class="text-center text-muted">
              Không có bản ghi nào!
            </div>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
    <income-expense-type-handler
      :item="{id: 0}"
      @refetch-income-types="fetchData"
    />
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BModal,
  VBModal,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import IncomeExpenseTypeHandler from '@/views/settings/etc/income-expense-type/handler/IncomeExpenseTypeHandler.vue';
import TableActions from '@/views/components/TableActions.vue';
import useSelectIncomeExpenseModal from "./useSelectIncomeExpenseTypeModal";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BModal,
    BButton,
    VueGoodTable,
    IncomeExpenseTypeHandler,
    TableActions,

  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    selectedItems: {
      type: Array,
      default: null,
    },
  },
  computed: {
    checkIsSubmit() {
      return (
        (this.selectedRows.length > 1 && this.isDormitory) || !this.selectedRows.length
      );
    },
  },
  created() {
    this.fetchData();
  },
  setup(props) {
    const {
      columns,
      rows,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resetValue,
      deleteIncomeExpenses,
    } = useSelectIncomeExpenseModal(props);

    return {
      columns,
      rows,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resetValue,
      deleteIncomeExpenses,
    };
  },

  methods: {
    handleSubmit() {
      this.$emit("onSelect", this.selectedRows);
    },
    onDelete(incomeExpenseType) {
      const deleteObjects = incomeExpenseType && incomeExpenseType.id > 0 ? [incomeExpenseType] : this.selectedRows;
      this.deleteIncomeExpenses(deleteObjects);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
