<template>
  <div>
    <b-modal
      id="modal-income-expense"
      ref="refModal"
      title="Thu/Chi"
      size="lg"
      :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
      :ok-disabled="isSubmitting"
      cancel-title="Huỷ"
      cancel-variant="outline-secondary"
      :hide-header-close="true"
      no-close-on-backdrop
      scrollable
      @show="resetModal"
      @hidden="resetModal"
      @ok="onSubmit"
    >
      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-alert
          :show="itemLocal.reservation !== null && itemLocal.id > 0 && itemLocal.reservation.id > 0"
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">Lưu ý: Phiếu thu/chi gắn với đặt đặt cọc nên chỉ cho phép chỉnh sửa 1 số thông tin. Để cập nhật nhà, phòng, giường, số tiền... vui lòng cập nhật tại thông tin đặt cọc.</span>
          </div>
        </b-alert>
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row v-if="!itemLocal.reservation">
            <b-col cols="6">
              <b-card
                no-body
                :class="`shadow-none border ${itemLocal.isIncome ? 'bg-light-primary' : 'bg-light-secondary'} `"
                @click="changeType(true)"
              >
                <b-card-header>
                  <b-card-title>Phiếu thu</b-card-title>
                  <b-form-radio
                    v-model="itemLocal.isIncome"
                    name="isIncome"
                    :value="true"
                    class="custom-control-primary"
                  />
                </b-card-header>
              </b-card>

            </b-col>
            <b-col>
              <b-card
                no-body
                :class="`shadow-none border ${!itemLocal.isIncome ? 'bg-light-danger' : 'bg-light-secondary'} `"
                @click="changeType(false)"
              >
                <b-card-header>
                  <b-card-title>Phiếu chi</b-card-title>
                  <b-form-radio
                    v-model="itemLocal.isIncome"
                    name="isIncome"
                    :value="false"
                    class="custom-control-danger"
                  />
                </b-card-header>
              </b-card>

            </b-col>
          </b-row>
          <b-row class="mt-2 mb-1">
            <b-col
              cols="12"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  1. Thông tin chung
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                :default-value="itemLocal.apartment"
                label="Tòa nhà"
                :enable="!itemLocal.reservation"
              />
            </b-col>
            <b-col md="6">
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                :default-value="itemLocal.room"
                label="Phòng"
                :apartment="itemLocal.apartment"
                :enable="!itemLocal.reservation"
              />
            </b-col>

          </b-row>
          <b-row>
            <b-col
              v-if="resolveColWidthIfDisableBed(true) > 0"
              :md="resolveColWidthIfDisableBed()"
            >
              <select-bed
                v-model="itemLocal.bed"
                :default-value="itemLocal.bed"
                :filter-active="true"
                label="Giường"
                :room="itemLocal.room"
                :enable="!itemLocal.reservation"
              />
            </b-col>
            <b-col :md="resolveColWidthIfDisableBed()">
              <!-- <select-tenant
                v-model="itemLocal.tenant"
                :default-value="itemLocal.tenant"
                label="Khách hàng"
                :enable="!itemLocal.reservation"
              /> -->
              <select-contract
                v-model="itemLocal.contract"
                :default-value="itemLocal.contract"
                label="Khách hàng"
                :enable="!itemLocal.reservation"
                :apartment="itemLocal.apartment"
                :room="itemLocal.room"
                :auto-select="false"
              />
            </b-col>
          </b-row>

          <b-row>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="itemLocal.isIncome === true ? `Tên phiếu thu/Lý do thu` : `Tên phiếu chi/Lý do chi`"
                >
                  <template
                    v-slot:label
                  >
                    {{ itemLocal.isIncome === true ? `Tên phiếu thu/Lý do thu` : `Tên phiếu chi/Lý do chi` }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Thuê nhà tháng 5"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="itemLocal.isIncome"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Tên người nộp"
                rules="required"
              >
                <b-form-group
                  label="Tên người nộp"
                >
                  <template
                    v-slot:label
                  >
                    Tên người nộp
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="payer"
                    v-model="itemLocal.payer"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="itemLocal.isIncome === false"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Tên người nhận"
                rules="required"
              >
                <b-form-group
                  label="Tên người nhận"
                >
                  <template
                    v-slot:label
                  >
                    Tên người nhận
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="receiver"
                    v-model="itemLocal.receiver"
                    placeholder=""
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="isTingTong ? 'Hình thức thanh toán' : 'Sổ quỹ'"
                rules="required"
              >
                <select-cashbook
                  v-model="itemLocal.cashbook"
                  required
                  :enable-add="true"
                  :default-value="itemLocal.cashbook"
                  :label="isTingTong ? 'Hình thức thanh toán' : 'Sổ quỹ'"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="itemLocal.isIncome === true ? `Ngày thực thu` : `Ngày thực chi`"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.issueDate"
                  required
                  :default-value="itemLocal.issueDate"
                  :label="itemLocal.isIncome === true ? `Ngày thực thu` : `Ngày thực chi`"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Ghi chú"
              >
                <b-form-textarea
                  v-model="itemLocal.note"
                  rows="3"
                />
              </b-form-group>

            </b-col>
          </b-row>
          <b-row v-if="!itemLocal.isIncome">
            <b-col :cols="itemLocal.repeatCycleObject && parseInt(itemLocal.repeatCycleObject.value, 10) !== 0 ? 6 : 12">
              <select-repeat-cycle
                v-model="itemLocal.repeatCycleObject"
                :default-value="itemLocal.repeatCycleObject"
                label="Tự động lặp"
              />
            </b-col>
            <b-col
              v-if="itemLocal.repeatCycleObject && parseInt(itemLocal.repeatCycleObject.value, 10) !== 0"
              md="6"
            >
              <b-form-group label="Số lần lặp">
                <b-input-group append="lần">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox v-model="itemLocal.repeatInfinity">
                      Không giới hạn
                    </b-form-checkbox>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="itemLocal.repeatCount"
                    :disabled="itemLocal.repeatInfinity"
                    placeholder="∞"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div
                  class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
                >
                  2. Hạng mục
                </div>
                <div
                  v-if="!itemLocal.reservation"
                  class="ml-auto"
                >
                  <b-button

                    v-b-modal.modal-select-income-expense-type
                    variant="primary"
                    class="btn-icon ml-auto"
                    size="sm"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>

              </div>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="items"
                rules="required"
              >
                <items-table
                  v-model="itemLocal.items"
                  :rows="itemLocal.items"
                  :editable="!itemLocal.reservation"
                />
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="itemLocal.allocation"
                >
                  Hạch toán kết quả kinh doanh?
                </b-form-checkbox>

              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="!itemLocal.id">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. Đính kèm
                </div>
              </div>
            </b-col>
            <b-col cols="12">

              <media-view
                v-model="itemLocal.attachments"
                :label="null"
                :initial-value="itemLocal.attachments"
                :upload-imediately="true"
                @on-delete-uploaded-file="onDeleteAttachment"
              />

            </b-col>
          </b-row>

          <!-- Allocation -->

          <!-- <b-row
            v-if="itemLocal.allocation"
            class="mt-2"
          >
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. Bảng phân bổ
                </div>

              </div>
            </b-col>
            <b-col cols="12">
              <allocation-table :allocations="allocations" />
            </b-col>
          </b-row> -->

        </b-form>
      </validation-observer>
    </b-modal>
    <select-income-expense-type-modal
      :selected-items="itemLocal.items"
      @onSelect="onSelectIncomeExpenseType"
    />
  </div>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormRadio,
  BButton,
  VBModal,
  BAlert,
  BCard,
  BCardHeader,
  BCardTitle,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectCashbook from '@/views/components/SelectCashbook.vue';
import SelectIncomeExpenseTypeModal from '@/views/settings/etc/income-expense-type/select/SelectIncomeExpenseTypeModal.vue';
import SelectContract from '@/views/components/SelectContract.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import SelectRepeatCycle from '@/views/components/SelectRepeatCycle.vue';
import useIncomeExpenseModal from './useIncomeExpenseModal';
import ItemsTable from './ItemsTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAlert,
    BFormRadio,
    BCard,
    BCardHeader,
    BCardTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    SelectApartment,
    SelectRoom,
    SelectDate,
    SelectBed,
    SelectCashbook,
    SelectContract,
    ItemsTable,
    SelectIncomeExpenseTypeModal,
    MediaView,
    SelectRepeatCycle,
  },
  directives: {
    'v-modal': VBModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      isTingTong: process.env.VUE_APP_PROVIDER === 'tingtong',
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      allocations,
      isSubmitting,
      changeType,
      resetItemLocal,
      resetModal,
      onSelectIncomeExpenseType,
      onSubmit,
      resolveColWidthIfDisableBed,
      onDeleteAttachment,
    } = useIncomeExpenseModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      allocations,
      changeType,
      resetItemLocal,
      resetModal,
      onSubmit,
      onSelectIncomeExpenseType,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      resolveColWidthIfDisableBed,
      onDeleteAttachment,
    };
  },
};
</script>
