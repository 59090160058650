import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import incomeExpenseTypeStoreModule from '../incomeExpenseTypeStoreModule';

export default function useSelectIncomeExpenseModal(props) {
  const STORE_MODULE_NAME = 'incomeExpenseType';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpenseTypeStoreModule);
  }
  // Use toast
  const toastification = toast();

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '60px',
    },
    {
      label: 'Tên hạng mục',
      field: 'name',
    },
    {
      label: 'Thao tác',
      field: 'action',
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchData = () => {
    isLoading.value = true;
    store
      .dispatch('incomeExpenseType/fetchIncomeExpenseTypes', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        const data = [...items];
        if (props.selectedItems && props.selectedItems.length) {
          const listIdSelected = props.selectedItems.map(obj => obj.incomeExpenseType.id);
          const dataRes = data.filter(res => !listIdSelected.includes(res.id));
          rows.value = [...dataRes];
        } else rows.value = [...items];
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteIncomeExpenses = objects => {
    store
      .dispatch('incomeExpenseType/deleteIncomeExpenseTypes', {
        ids: objects.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const resetValue = () => {
    rows.value = [];
    // filter
    selectedRows.value = [];
    // ssr
    isLoading.value = false;
    totalRecords.value = 0;
    serverParams.value = {
      searchTerm: '',
      filter: {},
      sort: {},
      page: 1,
      perPage: 20,
    };
  };

  return {
    columns,
    rows,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    resetValue,
    selectionChanged,
    deleteIncomeExpenses,
  };
}
